<template>
  <v-container fluid>
    <TrainerTopTabs
        :add-trainer="false"
        :venue-service-ids="[]"
        :venue-services="[]"
    />
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>



    <v-row>
      <trainer-sales
        :params="params"
        :showProductType="false"
        stackType="service"
        :exportPermission="
          checkExportPermission($modules.trainers.dashboard.slug)
        "
      ></trainer-sales>
    </v-row>
  </v-container>
</template>

<script>
import TrainerSales from "@/components/Chart/OrderSalesGraph";
import TrainerTopTabs from "@/views/Trainers/TrainerTabs/TrainerTopTabs.vue";
export default {
  components: {
    TrainerTopTabs,
    TrainerSales,
  },
  data() {
    return {
      btnShow: false,
      params: { venue_service_ids: [], product_ids: [], product_type_ids: [5] },
    };
  },

  methods:{
    gotoPage(route){
      this.$router.push(route);
    },
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
};
</script>


<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}

.active_qp_tab{
  border-bottom: 2px solid black;
  border-radius: 0px;
}

.light-blue-text{
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>